import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";

import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { persistStore, persistReducer } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";

import ActionType from "../../resources/enums/index";
import AuthReducer from "./AuthReducer";
import ArtWorkReducer from "./ArtWorkReducer";
import OrderReducer from "./OrderReducer";
import SettingReducer from "./SettingReducer";
import notificationReducer from "./ListReducer";

const appReducer = combineReducers({
  AuthReducer,
  ArtWorkReducer,
  OrderReducer,
  SettingReducer,
  notificationReducer
});

let rootReducer = null;

rootReducer = (state: any, action: { type: ActionType }) => {
  let tmp = state;
  if (action.type === ActionType.LOGOUT) {
    tmp = undefined;
  }

  return appReducer(tmp, action);
};

const transforms = [
  encryptTransform({
    secretKey: "ADHKHKDHKDKHKSHKHDSKHKSDKJ",
    onError: (error) => {
      // Handle the error.
      console.error("transforms Error", error);
    },
  }),
];

const persistConfig = {
  key: "root",
  storage,
  transforms,
  stateReconciler: autoMergeLevel2,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
  persistedReducer,

  applyMiddleware(thunk, logger)
);

export default store;
persistStore(store);
export type RootState = ReturnType<typeof rootReducer>;
