import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import footerLogo from "../../assets/images/footer-logo.png";
// import authorize from "../../assets/images/authorize.png";

import stripeLogo from "../../assets/images/stripe-logo.svg";
import ROUTES from "../../constant/routes";

const footerPages = [
  { link: ROUTES.CONTACT, text: "contact_us" },
  { link: ROUTES.PRIVACY_POLICY, text: "privacy_policy" },
  { link: ROUTES.TERMS_CONDITION, text: "terms_and_condition" },
];

const Footer: React.FC = () => {
  const year = new Date().getFullYear();

  const { t: translation } = useTranslation();
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <img className="logo" src={footerLogo} alt="" />
          </div>
          <div className="col-md-2 offset-md-4">
            <h6 className="fw-6 mb-3">{translation("company")}</h6>
            {footerPages.map(
              (item: { link: string; text: string }, index: number) => (
                <p key={index} className="op-6">
                  <Link to={item.link}>{translation(item.text)}</Link>
                </p>
              )
            )}
          </div>
        </div>
        <div className="row">
          <div className="header-bottom">
            <div className="d-flex justify-content-between align-items-center mt-5">
              <div className="d-flex align-items-center justify-content-end">
                <h5 className="me-4">{translation("payment_options")}</h5>
                <img
                  className="me-2 payment-option-img"
                  src={stripeLogo}
                  alt=""
                  height="22px"
                />
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="footer-sub-center">
              <p>
                {translation("copyright")} {year} {translation("udder_color")}
              </p>
              <p>
                {translation("copyright")} {year} {translation("copyright_tag")}{" "}
              </p>
            </div>
            <div className="footer-sub-right">
              <p>{translation("developed")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
