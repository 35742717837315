import React, { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ProtectedRoutes from "./ProtectedRoutes";
import HeaderAdmin from "../components/admin/header/HeaderAdmin";
import Sidebar from "../components/admin/sidebar/Sidebar";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Loading from "../components/loading/Loading";
import ROUTES from "../constant/routes";

import { IAuthReducerState } from "../redux/reducers/AuthReducer";
import { ROLES } from "../constant/commonConstants";
import { RootState } from "../redux/reducers/Index";
import SheetInformation from "../container/admin/SheetInformation";
import Settigs from "../container/admin/Settings";

const Login = lazy(() => import("../container/userAuth/Login"));
const Home = lazy(() => import("../container/home/Home"));
const Forget = lazy(() => import("../container/userAuth/Forget"));
const SignUp = lazy(() => import("../container/userAuth/SignUp"));
const Verification = lazy(() => import("../container/userAuth/Verification"));
const Reset = lazy(() => import("../container/userAuth/Reset"));
const MyProfile = lazy(() => import("../container/myProfile/MyProfile"));
const ContactUs = lazy(() => import("../container/contact/ContactUs"));
const Users = lazy(() => import("../container/admin/Users"));
const Plans = lazy(() => import("../container/plan/Plans"));
const UploadArt = lazy(() => import("../container/uploadArtWork/UploadArt"));
const ShippingAddress = lazy(
  () => import("../container/shipping/ShippingAddress")
);
const JobConfirmation = lazy(
  () => import("../container/JobConfirmaion/JobConfirmation")
);
const Payment = lazy(() => import("../container/payment/Payment"));
const JobRequest = lazy(() => import("../container/admin/JobRequest"));
const Dashboard = lazy(() => import("../container/admin/dashboard/Dashboard"));
const OrderAdmin = lazy(() => import("../container/admin/Order"));
const OrderDetail = lazy(() => import("../container/admin/OrderDetails"));
const ArtWorkList = lazy(() => import("../container/admin/ArtWorkList"));
const MyOrder = lazy(() => import("../container/myOrder/MyOrder"));
const PaymentAdmin = lazy(() => import("../container/admin/Payment"));
const ReviewOrder = lazy(() => import("../container/reviewOrder/ReviewOrder"));
const UploadImages = lazy(
  () => import("../container/updateOrderImages/UpdateOrderImages")
);
const NotifiactionList = lazy(
  () => import("../container/admin/NotificationList")
);
const TermsCondition = lazy(
  () => import("../container/StaticPages/TermsCondition")
);
const PrivacyPolicy = lazy(
  () => import("../container/StaticPages/PrivacyPolicy")
);
const UserOrderDetails = lazy(
  () => import("../container/myOrder/UserOrderDetails")
);
const PackageDetail = lazy(() => import("../container/admin/PackageDetail"));
const SheetWeight = lazy(() => import("../container/admin/SheetWeight"));

interface Props {
  component: React.ComponentType;
}

/**
 * Display specific components with Header and Bottom Navbar
 * @param props route path and component
 * @returns component wrappped with Header and BottomNavbar
 */

export const WithHeaderAndFooter: React.FC<Props> = ({ component }) => {
  return (
    <div className="main">
      <Header />
      <div className="main-wrapper">
        <ProtectedRoutes component={component} />
      </div>
      <Footer />
    </div>
  );
};

export const WithHeaderAndSideBar: React.FC<Props> = ({ component }) => {
  return (
    <div className="main">
      <Sidebar />
      <div className="main-box">
        <div className="p-4 pb-0">
          <div className="container-fluid">
            <HeaderAdmin />
          </div>
        </div>
        <ProtectedRoutes component={component} />
      </div>
    </div>
  );
};

const PublicRoute = () => {
  const authReducer: IAuthReducerState = useSelector(
    (state: RootState) => state?.AuthReducer
  );
  const { t: translation } = useTranslation();

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />

        <Route path={ROUTES.CONTACT} element={<ContactUs />} />
        <Route path={ROUTES.TERMS_CONDITION} element={<TermsCondition />} />
        <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route path={ROUTES.PLANS} element={<Plans />} />
        <Route
          path={ROUTES.LOGIN}
          element={
            authReducer.isLoggedIn ? (
              <Navigate
                to={
                  authReducer.authData.account_type === ROLES.USER
                    ? ROUTES.HOME
                    : ROUTES.DASHBOARD_ADMIN
                }
              />
            ) : (
              <Login />
            )
          }
        />
        <Route
          path={ROUTES.ADMIN_LOGIN}
          element={
            authReducer.isLoggedIn ? (
              <Navigate
                to={
                  authReducer.authData.account_type === ROLES.USER
                    ? "/"
                    : authReducer.authData.account_type === ROLES.ADMIN
                    ? ROUTES.DASHBOARD_ADMIN
                    : ROUTES.JOB_REQUEST
                }
              />
            ) : (
              <Login />
            )
          }
        />
        <Route
          path={ROUTES.FORGET_PASSWORD}
          element={
            authReducer.isLoggedIn ? (
              <Navigate
                to={
                  authReducer.authData.account_type === ROLES.USER
                    ? "/"
                    : ROUTES.DASHBOARD_ADMIN
                }
              />
            ) : (
              <Forget />
            )
          }
        />
        <Route
          path={ROUTES.SIGN_UP}
          element={
            authReducer.isLoggedIn ? (
              <Navigate
                to={
                  authReducer.authData.account_type === ROLES.USER
                    ? "/"
                    : ROUTES.DASHBOARD_ADMIN
                }
              />
            ) : (
              <SignUp />
            )
          }
        />
        <Route
          path={ROUTES.VERIFY}
          element={
            authReducer.isLoggedIn ? (
              <Navigate
                to={
                  authReducer.authData.account_type === ROLES.USER
                    ? "/"
                    : ROUTES.DASHBOARD_ADMIN
                }
              />
            ) : (
              <Verification />
            )
          }
        />
        <Route
          path={ROUTES.RESET}
          element={
            authReducer.isLoggedIn ? (
              <Navigate
                to={
                  authReducer.authData.account_type === ROLES.USER
                    ? "/"
                    : ROUTES.DASHBOARD_ADMIN
                }
              />
            ) : (
              <Reset />
            )
          }
        />

        {/* user routes  */}
        <Route
          path={ROUTES.PROFILE}
          element={<WithHeaderAndFooter component={MyProfile} />}
        />
        <Route
          path={ROUTES.UPLOAD_ARTWORK}
          element={<WithHeaderAndFooter component={UploadArt} />}
        />
        <Route
          path={ROUTES.UNPAID_ORDERS_WITH_UPLOAD_ARTWORK}
          element={<WithHeaderAndFooter component={UploadArt} />}
        />
        <Route
          path={ROUTES.SHIPPING_ADDRESS}
          element={<WithHeaderAndFooter component={ShippingAddress} />}
        />
        <Route
          path={ROUTES.SHIPPING_ADDRESS_UNPAIDORDERS}
          element={<WithHeaderAndFooter component={ShippingAddress} />}
        />
        <Route
          path={ROUTES.JOB_CONFIRM}
          element={<WithHeaderAndFooter component={JobConfirmation} />}
        />
        <Route
          path={ROUTES.JOB_CONFIRM_ORDERID}
          element={<WithHeaderAndFooter component={JobConfirmation} />}
        />
        <Route
          path={ROUTES.PAYMENT}
          element={<WithHeaderAndFooter component={Payment} />}
        />
        <Route
          path={ROUTES.PAYMENT_ORDERID}
          element={<WithHeaderAndFooter component={Payment} />}
        />
        <Route
          path={ROUTES.PAYMENT_PENDING_TOKEN}
          element={<WithHeaderAndFooter component={Payment} />}
        />
        <Route
          path={ROUTES.USER_ORDER_LIST}
          element={<WithHeaderAndFooter component={MyOrder} />}
        />
        <Route
          path={ROUTES.VIEW_ORDER_STATUS}
          element={<WithHeaderAndFooter component={ReviewOrder} />}
        />
        <Route
          path={ROUTES.UPDATE_ORDER_IMAGES}
          element={<WithHeaderAndFooter component={UploadImages} />}
        />
        <Route
          path={ROUTES.USER_ORDER_DETAILS}
          element={<WithHeaderAndFooter component={UserOrderDetails} />}
        />

        {/* admin routes  */}
        <Route
          path={ROUTES.USER_LIST}
          element={<WithHeaderAndSideBar component={Users} />}
        />
        <Route
          path={ROUTES.DASHBOARD_ADMIN}
          element={<WithHeaderAndSideBar component={Dashboard} />}
        />
        <Route
          path={ROUTES.ADMIN_ORDER_LIST}
          element={<WithHeaderAndSideBar component={OrderAdmin} />}
        />
        <Route
          path={ROUTES.ORDER_DETAIL}
          element={<WithHeaderAndSideBar component={OrderDetail} />}
        />
        <Route
          path={ROUTES.PAYMENT_LIST}
          element={<WithHeaderAndSideBar component={PaymentAdmin} />}
        />
        <Route
          path={ROUTES.NOTIFICATION}
          element={<WithHeaderAndSideBar component={NotifiactionList} />}
        />

        <Route
          path={ROUTES.SHEET_INFORMATION}
          element={<WithHeaderAndSideBar component={SheetInformation} />}
        />
        <Route
          path={ROUTES.SETTINGS}
          element={<WithHeaderAndSideBar component={Settigs} />}
        />
        <Route
          path={ROUTES.PACKAGE_DETAIL}
          element={<WithHeaderAndSideBar component={PackageDetail} />}
        />
        <Route
          path={ROUTES.SHEET_WEIGHT}
          element={<WithHeaderAndSideBar component={SheetWeight} />}
        />

        {/* artist routes  */}
        <Route
          path={ROUTES.JOB_REQUEST}
          element={<WithHeaderAndSideBar component={JobRequest} />}
        />
        <Route
          path={ROUTES.ARTWORK_LIST}
          element={<WithHeaderAndSideBar component={ArtWorkList} />}
        />
        <Route
          path="*"
          element={
            <h1 className="text-center"> {translation("unauthorised_page")}</h1>
          }
        />
      </Routes>
    </Suspense>
  );
};
export default PublicRoute;
