enum ActionType {
  LOGIN = "LOGIN",
  UPDATE_PERMISSIONS = "UPDATE_PERMISSIONS",
  UPDATEAUTHDATA = "UPDATEAUTHDATA",
  FORGOTPASSWORD = "FORGOTPASSWORD",
  LOGOUT = "LOGOUT",
  LOCALSTORAGE = "localstorage",
  DEFAULT = "DEFAULT",
  FAILURE = "FAILURE",
  RESET_PASSWORD = "reset_password",
  USER_ACCOUNT_TYPES = "user_account_types",
  ERROR_HANDLING = "error_handling",
  UNAUTHORIZED_ERROR = "unauthorized_error",
  NOT_FOUND_ERROR = "not_found_error",
  INTERNAL_SERVER_ERROR = "internal_server_error",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  UPDATE_AUTH = "UPDATE_AUTH",
  CLEAR_AUTH = "CLEAR_AUTH",
  CHANGEPASSWORD = "CHANGE_PASSWORD",
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  EDIT_PROFILE = "EDIT_PROFILE",
  EDIT_IMAGE = "EDIT_IMAGE",
  UPLOAD_ARTWORK = "UPLOAD_ARTWORK",
  CLEAR_ARTWORK = "CLEAR_ARTWORK",
  USER_ORDER_INFO = "USER_ORDER_INFO",
  ORDER_EMPTY = "ORDER_EMPTY",
  ORDER_DETAILS_INFO = "ORDER_DETAILS_INFO",
  SHEET_INFO = "SHEET_INFO",
  SHIPPING_SETTINGS = "SHIPPING_SETTINGS",
  EMPTY_SHIPPING_SETTINGS = "EMPTY_SHIPPING_SETTINGS",
  SHEET_WEIGHT = "SHEET_WEIGHT",
  UPDATTE_ORDER_INFO = "UPDATTE_ORDER_INFO",
  UPDATTE_USER_MEMBERSHIP_PLAN = "UPDATTE_USER_MEMBERSHIP_PLAN",
  UPDATE_AUTHORIZR_PROFILE_ID = "UPDATE_AUTHORIZR_PROFILE_ID",
  UPDATE_STRIPE_PROFILE_ID = "UPDATE_STRIPE_PROFILE_ID",
}

export default ActionType;
