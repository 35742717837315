import React from "react";
import { useTranslation } from "react-i18next";
import close from "../../assets/images/close.png";
import CommonButton from "../commonFields/CommonButton";

interface Iprops {
  heading: string;
  description?: string;
  yesButtonText?: string;
  noButtonText?: string;
  onClickOkay: () => void;
  onClickCancel: () => void;
  disabled?: boolean;
}

const ConfirmationModal: React.FC<Iprops> = (props) => {
  const { t: translation } = useTranslation();
  const {
    heading,
    description,
    yesButtonText = translation("yes"),
    noButtonText = translation("no"),
    onClickOkay,
    onClickCancel,
    disabled,
  } = props;
  return (
    <div
      className="modal modal-custom-show"
      id="exampleModal"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body text-center p-5 close-btn">
            <CommonButton
              type="button"
              className="btn-close close-inner theme-button"
              onClick={onClickCancel}
            >
              <img src={close} alt="" />
            </CommonButton>

            <h4 className="mb-3 space">
              <b>
                <span className="color-theme">{translation(heading)}</span>
              </b>
            </h4>
            <p className="mb-2">{translation(description as string)}</p>
            <div className="d-flex">
              <CommonButton
                className="theme-button black-btn-outline w-100"
                type="button"
                onClick={onClickCancel}
              >
                {noButtonText}
              </CommonButton>

              <CommonButton
                className="theme-button primary-btn w-100"
                type="button"
                onClick={() => onClickOkay()}
                disabled={disabled}
              >
                {yesButtonText}{" "}
                {disabled && (
                  <div className="spinner-border text-light" role="status" />
                )}
              </CommonButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
