import * as http from "../utils/http";
import endpoint from "../constant/endPoint";
import ApiResponse from "../resources/entity/IApiResponse";
import { IsettingData } from "../interfaces/settingInterface";

/**
 * Update settings
 * @param data Setting info
 * @returns
 */
const updateSettings = (data: IsettingData): Promise<ApiResponse> =>
  http.post(endpoint.setting.UPDATE_SETTING, data);

/**
 * Get settings
 * @returns
 */
const getSetttings = (): Promise<ApiResponse> =>
  http.get(endpoint.setting.GET_SETTING_INFO);

export { updateSettings, getSetttings };
