import * as http from "../utils/http";
import endpoint from "../constant/endPoint";
import ApiResponse from "../resources/entity/IApiResponse";
import { NotificationListParms } from "../interfaces/userInterface";

// Get notification list
const notificationList = (data: NotificationListParms): Promise<ApiResponse> =>
  http.get(endpoint.notification.NOTIFICATION_LIST, data);

export default notificationList;
