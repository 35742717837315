import * as yup from "yup";
import { TFunction } from "react-i18next";
import {
  firstName,
  lastName,
  email,
  phone,
  zip,
  state,
  city,
  addressLine1,
  addressLine2,
  alphaNumeric,
  password,
  companyName,
  Subject,
} from "./commonFieldsVerification";
import { companyNameRegex } from "../constant/commonConstants";

const userProfileValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    first_name: firstName(translation),
    last_name: lastName(translation),
    email: email(translation),
    phone: phone(translation),
    company_name: companyName(translation, "profile")
      .matches(companyNameRegex, translation("invalid_company_name"))
      .trim()
      .test(
        "min-length",
        translation("company_name_should_not_be_less_than", { limit: 3 }),
        (value) => {
          const cleanedValue = `${value}`.replace(
            /[!@#$%^&\\*()_+\-=[\]{}|\\;:'",<>.?/`~]/g,
            ""
          );
          return cleanedValue.length >= 3;
        }
      )
      .optional(),
      address_line_1: addressLine1(translation),
      address_line_2: addressLine2(translation),
      city: city(translation),
      state: state(translation),
      zip: zip(translation),
    order_status: alphaNumeric(translation, "message_status"),

  });
const loginValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    email: email(translation),
  });
const contactFormValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    first_name: firstName(translation),
    last_name: lastName(translation),
    email: email(translation),
    phone: phone(translation),
    subject: Subject(translation),
    message: yup
      .string()
      .trim()
      .required(
        translation("required_field_error", {
          field_label: translation("write_message"),
        })
      ),
  });
const changePasswordValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    old_password: password(translation),
    password: password(translation),
    confirm_password: yup
      .string()
      .required(translation("confirm_pass_req"))
      .oneOf(
        [yup.ref("password"), null],
        translation("confirm_password_must_match")
      ),
  });

export {
  userProfileValidationSchema,
  loginValidationSchema,
  contactFormValidationSchema,
  changePasswordValidationSchema,
};
