import React from "react";
import {
  UseFormRegister,
  FieldErrors,
  FieldValues,
  Control,
  Controller,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import CommonErrorText from "./CommonErrorText";

interface IOptions {
  id?: string | number;
  name?: string;
  value?: string | number;
  _id?: string;
}

interface Iprops {
  option: IOptions[];
  errors?: FieldErrors<FieldValues>;
  register?: UseFormRegister<FieldValues>;
  name: string;
  className: string;
  firstOption?: string;
  showFirstOption?: boolean;
  defaultValue?:
    | {
        name: string;
        id: string;
      }
    | string;
  control?: Control;
  disabled?: boolean;
  label?: string;
  required?: boolean;
  onChange?: (value: string) => void;
}

const CommonSelect: React.FC<Iprops> = (props) => {
  const {
    option,
    errors,
    className = "",
    register,
    onChange,
    name,
    defaultValue = "",
    control,
    firstOption,
    disabled,
    label,
    showFirstOption = true,
    required,
  } = props;
  const { t } = useTranslation();

  return (
    <>   
      {label && (
        <label htmlFor={name} className="font14">
          {label}
          {required ? <sup className="colorRed">*</sup> : null}
        </label>
      )}
      {register ? (
        <select
          className="form-select form-select-sm me-3"
          aria-label=".form-select-sm example"
          {...register(name)}
          defaultValue={defaultValue as string}
          disabled={disabled}
        >
          {showFirstOption && (
            <option value="">
              {firstOption ? firstOption : t("default_option")}
            </option>
          )}

          {option?.map((item) => (
            <option key={item._id} value={item._id}>
              {item.name ? item.name : item.value}
            </option>
          ))}
        </select>
      ) : (
        <Controller
          render={({ field }) => (        
            <select {...field} className={className} 
            onChange={(e) => {
              field.onChange(e);
              onChange && onChange(e.target.value);
            }}
              disabled={disabled}>              
              {showFirstOption && (
              <option disabled  selected value="">
                {firstOption ? firstOption : t("default_option")}
              </option>
               )}
               
              {option?.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name ?? item.value}
                </option>
              ))}
            </select>          
          )}          
          name={name}
          control={control}
          defaultValue={defaultValue as string}
        />
      )}

      {/* Common Error message component  */}
      <CommonErrorText errors={errors} />
    </>
  );
};

export default CommonSelect;
