import * as http from "../utils/http";
import endpoint from "../constant/endPoint";
import ApiResponse from "../resources/entity/IApiResponse";

import {
  IChangePassowrd,
  IContactForm,
  UserListParms,
} from "../interfaces/userInterface";
import { IMemberShip, UserProfileData } from "../interfaces/userAuthInterface";

// Contact us
const contactUs = (data: IContactForm): Promise<ApiResponse> =>
  http.post(endpoint.user.CONTACT_US, data);

// update Profile
const updateProfile = (data: UserProfileData): Promise<ApiResponse> =>
  http.post(endpoint.user.UPDATE_PROFILE, data);

// change user password
const changePassword = (data: IChangePassowrd): Promise<ApiResponse> =>
  http.patch(endpoint.user.CHANGE_PASSWORD, data);

// update user-profile
const updateProfileImage = (userId:number ,prevImage?:string,updatedUrl?:string):
 Promise<ApiResponse> =>
  http.patch(endpoint.user.UPDATE_PROFILE_IMAGE, {userId,prevImage,updatedUrl});

// change user status
const changeUserStatus = (
  isActive: number,
  userId: number
): Promise<ApiResponse> =>
  http.patch(endpoint.user.UPDATE_STATUS, { isActive, userId });

// get user information
const getUserInfo = (id: number): Promise<ApiResponse> =>
  http.get(endpoint.user.GET_INFO, { id });

// get User List data
const userList = async (data?: UserListParms): Promise<ApiResponse> =>
  http.get(endpoint.user.GET_USER_LIST, data);

// update member ship plan
const updateMemberShipPlan = (data: IMemberShip): Promise<ApiResponse> =>
  http.post(endpoint.user.UPDATE_MEMBERSHIP_PLAN, data);

// update tax plan
const updateTaxPlan = (data: {
  userId: number;
  isTaxExempted: number;
}): Promise<ApiResponse> => http.post(endpoint.user.UPDATE_TAX_PLAN, data);

// get user unreadnotification count
const getUnreadNotification = (data: {
  userType: string;
}): Promise<ApiResponse> =>
  http.get(endpoint.user.GET_UNREAD_NOTIFICATION, data);

// change notification status
const updateNotificationStatus = (data: {
  userType: string;
  notificationId?:number
}): Promise<ApiResponse> =>
  http.patch(endpoint.user.UPDATE_UNREAD_NOTIFICATION, data);

// Login as admin
const loginAsUser = (data: {
  authUserId: number;
  userId: number;
}): Promise<ApiResponse> => http.post(endpoint.user.LOGIN_AS_USER, data);

// Get token
const loginUserByAdmin = (data: { token: string }): Promise<ApiResponse> =>
  http.post(endpoint.user.ADMIN_LOGIN_BY_TOKEN, data);
// get user status
const getUserStatus = (data: { email: string }): Promise<ApiResponse> =>
  http.get(endpoint.user.GET_USER_STATUS, data);

// get user membership
const getUserMemberShip = (data: { email: string }): Promise<ApiResponse> =>
  http.get(endpoint.user.GET_USER_MEMBERSHIP, data);

// Get token
const createUserAuthorizeId = (data: {
  userId: number;
}): Promise<ApiResponse> => http.post(endpoint.user.CREATE_AUTHORIZE_IDs, data);

const createUserStripeId = (data: { userId: number }): Promise<ApiResponse> =>
  http.post(endpoint.user.CREATE_STRIPE_ID, data);

const getPresignedUrl = (file: string, filePath: string, fileTypes: string): Promise<ApiResponse> =>
  http.post(endpoint.uploadArtWork.GET_PRESIGNEDURL, {file,filePath,fileTypes});

// ADD NEW ADDRESS
const addNewAddress=(data:unknown):Promise<ApiResponse> =>{
  return http.post(endpoint.user.ADD_NEW_ADDRESS,data);
};

// DELETE ADDRESS 
const deleteAddress=(address:unknown):Promise<ApiResponse> =>{
  return http.post(`${endpoint.user.REMOVE_ADDRESS}`,address);
};

const getMultipleAddress=(userId:number):Promise<ApiResponse> =>{
  return http.get(`${endpoint.user.GET_MULTIPLE_ADDRESS}?userId=${userId}`);
};

const getSignedUrl = (data: 
  {filePath: string;
  fileFormat: string;}
): Promise<ApiResponse> =>
  http.post(`${endpoint.uploadArtWork.GET_PRESIGNEDURL}`, data);

export {
  contactUs,
  updateProfile,
  changePassword,
  updateProfileImage,
  changeUserStatus,
  getUserInfo,
  userList,
  updateMemberShipPlan,
  updateTaxPlan,
  getUnreadNotification,
  updateNotificationStatus,
  loginAsUser,
  loginUserByAdmin,
  getUserStatus,
  getUserMemberShip,
  createUserAuthorizeId,
  createUserStripeId,
  getPresignedUrl,
  addNewAddress,
  deleteAddress,
  getMultipleAddress,
  getSignedUrl
};
