import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import CommonButton from "../../commonFields/CommonButton";
import ActionType from "../../../resources/enums";
import ConfirmationModal from "../../commonModals/ConfirmationModal";
import ROUTES from "../../../constant/routes";
import storage from "../../../utils/storage";
import toast from "../../../utils/toastsMessage";
import PasswordModal from "../../commonModals/PasswordModal";

import { ROLES } from "../../../constant/commonConstants";
import { IAuthReducerState } from "../../../redux/reducers/AuthReducer";
import { RootState } from "../../../redux/reducers/Index";
import { changePassword } from "../../../services/user";
import { IChangePassowrd } from "../../../interfaces/userInterface";

import "./Sidebar.scss";
import logo from "../../../assets/images/footer-logo.png";

const Sidebar: React.FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const handleLogOut = async () => {
    dispatch({
      type: ActionType.LOGOUT,
    });
    storage.removeAll();
    history(ROUTES.ADMIN_LOGIN);
  };

  const authReducer: IAuthReducerState = useSelector(
    (state: RootState) => state?.AuthReducer
  );
  const { t: translation } = useTranslation();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const changePasswordInfo = async (data: IChangePassowrd) => {
    setLoading(true);
    const res = await changePassword({
      old_password: data?.old_password,
      password: data.password,
    });
    if (res?.data?.success) {
      setShowPasswordModal(false);
      toast.success(translation(res?.data?.message));
    } else {
      toast.error(res?.data?.message ?? "something_went_wrong");
    }
    setLoading(false);
  };
  return (
    <>
      <div className="sidebar">
        <div className="d-flex flex-column h-100 justify-content-between">
          <div>
            <Link
              to={
                authReducer.authData.account_type === ROLES.ARTIST
                  ? ROUTES.JOB_REQUEST
                  : ROUTES.DASHBOARD_ADMIN
              }
            >
              <div className="logo ">
                <img src={logo} alt="" />
              </div>
            </Link>
            <ul>
              {authReducer.authData.account_type === ROLES.ADMIN && (
                <>
                  {" "}
                  <li
                    className={
                      window.location.pathname === ROUTES.DASHBOARD_ADMIN
                        ? "active"
                        : ""
                    }
                  >
                    <Link to={ROUTES.DASHBOARD_ADMIN}>
                      <h5>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 84 78.422"
                        >
                          <g
                            id="business-report"
                            transform="translate(0 -17)"
                            opacity="1"
                          >
                            <path
                              id="Path_21172"
                              data-name="Path 21172"
                              d="M76.617,17H7.383A7.391,7.391,0,0,0,0,24.383V72.289a7.391,7.391,0,0,0,7.383,7.383H31.22L29.415,90.5H26.25a2.461,2.461,0,0,0,0,4.922h31.5a2.461,2.461,0,0,0,0-4.922H54.585l-1.8-10.828H76.617A7.391,7.391,0,0,0,84,72.289V24.383A7.391,7.391,0,0,0,76.617,17ZM49.595,90.5H34.405l1.8-10.828H47.79ZM79.078,72.289a2.464,2.464,0,0,1-2.461,2.461H7.383a2.464,2.464,0,0,1-2.461-2.461V24.383a2.464,2.464,0,0,1,2.461-2.461H39.539v2.789a2.461,2.461,0,1,0,4.922,0V21.922H76.617a2.464,2.464,0,0,1,2.461,2.461Z"
                              transform="translate(0 0)"
                            />
                            <path
                              id="Path_21173"
                              data-name="Path 21173"
                              d="M88.461,128.75A2.461,2.461,0,0,0,86,131.211v8.039H80.422V123.336a2.461,2.461,0,0,0-4.922,0V139.25H69.922V115.461a2.461,2.461,0,0,0-4.922,0v26.25a2.461,2.461,0,0,0,2.461,2.461h21a2.461,2.461,0,0,0,2.461-2.461v-10.5A2.461,2.461,0,0,0,88.461,128.75Z"
                              transform="translate(-54.336 -80.25)"
                            />
                            <path
                              id="Path_21174"
                              data-name="Path 21174"
                              d="M287.273,121a14.266,14.266,0,0,0-10.2,24.244,1.627,1.627,0,0,0,.232.231A14.27,14.27,0,1,0,287.273,121Zm9.021,11.813h-6.56v-6.56A9.385,9.385,0,0,1,296.294,132.813Zm-11.482-6.56v8l-5.655,5.655A9.355,9.355,0,0,1,284.812,126.253Zm-2.175,17.137,5.655-5.655h8A9.355,9.355,0,0,1,282.637,143.39Z"
                              transform="translate(-228.211 -86.937)"
                            />
                          </g>
                        </svg>
                        {translation("dashboard")}
                      </h5>
                    </Link>
                  </li>
                  <li
                    className={
                      window.location.pathname === ROUTES.USER_LIST
                        ? "active"
                        : ""
                    }
                  >
                    <Link to={ROUTES.USER_LIST}>
                      <h5>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 84 84"
                        >
                          <g id="user" opacity="1">
                            <g
                              id="Group_17903"
                              data-name="Group 17903"
                              transform="translate(0)"
                            >
                              <path
                                id="Path_21160"
                                data-name="Path 21160"
                                d="M71.7,54.3a41.839,41.839,0,0,0-15.96-10.011,24.281,24.281,0,1,0-27.477,0A42.066,42.066,0,0,0,0,84H6.562a35.438,35.438,0,0,1,70.875,0H84A41.726,41.726,0,0,0,71.7,54.3ZM42,42A17.719,17.719,0,1,1,59.719,24.281,17.739,17.739,0,0,1,42,42Z"
                                transform="translate(0)"
                              />
                            </g>
                          </g>
                        </svg>
                        {translation("users_dashboard")}
                      </h5>
                    </Link>
                  </li>
                  <li
                    className={
                      window.location.pathname === ROUTES.PAYMENT_LIST
                        ? "active"
                        : ""
                    }
                  >
                    <Link to={ROUTES.PAYMENT_LIST}>
                      <h5>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 84 84"
                        >
                          <g
                            id="hand_2_"
                            data-name="hand (2)"
                            transform="translate(-0.501)"
                            opacity="1"
                          >
                            <path
                              id="Path_21161"
                              data-name="Path 21161"
                              d="M221.51,332.98a1.633,1.633,0,1,0-2.308,0A1.634,1.634,0,0,0,221.51,332.98Zm0,0"
                              transform="translate(-182.402 -276.01)"
                            />
                            <path
                              id="Path_21162"
                              data-name="Path 21162"
                              d="M188.449,17.948A17.948,17.948,0,1,0,206.4,0,17.968,17.968,0,0,0,188.449,17.948Zm32.632,0A14.685,14.685,0,1,1,206.4,3.264,14.7,14.7,0,0,1,221.081,17.948Zm0,0"
                              transform="translate(-156.974)"
                            />
                            <path
                              id="Path_21163"
                              data-name="Path 21163"
                              d="M19.4,260.746a1.647,1.647,0,0,0,2.316.013l7.954-7.793a4.911,4.911,0,0,0,1.168-5.063l1.711-1.641a4.908,4.908,0,0,1,3.416-1.373H57.775a14.732,14.732,0,0,0,10.3-4.161c.114-.11-.866,1.038,14.88-17.677a6.51,6.51,0,0,0-.78-9.2,6.6,6.6,0,0,0-9.207.728l-9.681,9.9a6.608,6.608,0,0,0-5.1-2.429H39.89a21.269,21.269,0,0,0-8.216-1.631c-7.9,0-14.8,3.629-18.5,10.432A4.918,4.918,0,0,0,8.785,232.2L.98,239.985a1.625,1.625,0,0,0,0,2.3Zm12.275-37.067a18,18,0,0,1,7.223,1.495,1.645,1.645,0,0,0,.658.137H58.186a3.263,3.263,0,1,1,0,6.526H44.8a1.631,1.631,0,1,0,0,3.263H58.186a6.514,6.514,0,0,0,6.5-7.379c9.365-9.574,10.686-10.919,10.742-10.986a3.305,3.305,0,0,1,4.626-.389,3.257,3.257,0,0,1,.385,4.605L65.728,238.446a11.458,11.458,0,0,1-7.952,3.181H35.964a8.181,8.181,0,0,0-5.7,2.287l-1.394,1.338L16.021,232.47c3-5.6,8.641-8.792,15.652-8.792ZM11.107,234.506a1.644,1.644,0,0,1,2.032-.23c.284.172-.531-.566,14.211,14.074a1.628,1.628,0,0,1,.009,2.3l-6.781,6.644L4.456,241.139Zm0,0"
                              transform="translate(0 -177.228)"
                            />
                            <path
                              id="Path_21164"
                              data-name="Path 21164"
                              d="M269.155,41.62v1.912a4.894,4.894,0,0,0,1.631,9.509,1.631,1.631,0,0,1,0,3.263,2.951,2.951,0,0,1-2.119-1.234,1.632,1.632,0,0,0-2.5,2.1,6.769,6.769,0,0,0,2.985,2.125v1.9a1.631,1.631,0,1,0,3.263,0V59.287a4.894,4.894,0,0,0-1.631-9.509,1.632,1.632,0,0,1,0-3.263,2.62,2.62,0,0,1,1.765.853,1.632,1.632,0,0,0,2.269-2.345,6.3,6.3,0,0,0-2.4-1.5v-1.9a1.631,1.631,0,1,0-3.263,0Zm0,0"
                              transform="translate(-221.716 -33.462)"
                            />
                          </g>
                        </svg>
                        {translation("dashboard_payments")}
                      </h5>
                    </Link>
                  </li>
                  <li
                    className={
                      window.location.pathname === ROUTES.ADMIN_ORDER_LIST
                        ? "active"
                        : ""
                    }
                  >
                    <Link to={ROUTES.ADMIN_ORDER_LIST}>
                      <h5>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 67.196 84"
                        >
                          <g
                            id="clipboard_1_"
                            data-name="clipboard (1)"
                            transform="translate(-47.037)"
                            opacity="1"
                          >
                            <g
                              id="Group_17905"
                              data-name="Group 17905"
                              transform="translate(47.037 0)"
                            >
                              <g
                                id="Group_17904"
                                data-name="Group 17904"
                                transform="translate(0 0)"
                              >
                                <path
                                  id="Path_21165"
                                  data-name="Path 21165"
                                  d="M105.681,11.006H97.943v-3.4c0-1.1-1.222-1.6-2.342-1.6H89.595c-1.425-4-4.989-6-9.061-6a9.265,9.265,0,0,0-9.061,6H65.567c-1.12,0-2.24.5-2.24,1.6v3.4H55.589a8.585,8.585,0,0,0-8.552,8.1V76.4c0,4.4,4.072,7.6,8.552,7.6h50.091c4.48,0,8.552-3.2,8.552-7.6v-57.3A8.585,8.585,0,0,0,105.681,11.006Zm-38.281-1H73a2.223,2.223,0,0,0,1.935-1.8,5.88,5.88,0,0,1,5.6-4.5,5.776,5.776,0,0,1,5.5,4.5,2.226,2.226,0,0,0,2.036,1.8h5.8v8H67.4ZM110.16,76.4c0,2.2-2.24,3.6-4.48,3.6H55.589c-2.24,0-4.48-1.4-4.48-3.6v-57.3a4.548,4.548,0,0,1,4.48-4.1h7.738v5.1a2.126,2.126,0,0,0,2.24,1.9H95.6a2.229,2.229,0,0,0,2.342-1.9v-5.1h7.738a4.549,4.549,0,0,1,4.48,4.1V76.4Z"
                                  transform="translate(-47.037 0)"
                                />
                                <path
                                  id="Path_21166"
                                  data-name="Path 21166"
                                  d="M114.661,230.935a2,2,0,0,0-2.8-.1l-6.4,6.1-2.7-2.8a2,2,0,0,0-2.8-.1,2.1,2.1,0,0,0,0,2.9l4.1,4.2a1.8,1.8,0,0,0,1.4.6,2,2,0,0,0,1.4-.6l7.8-7.4a1.9,1.9,0,0,0,.115-2.684C114.739,231.01,114.7,230.972,114.661,230.935Z"
                                  transform="translate(-89.122 -186.232)"
                                />
                                <path
                                  id="Path_21167"
                                  data-name="Path 21167"
                                  d="M223.546,256.034h-23a2,2,0,1,0,0,4h23a2,2,0,1,0,0-4Z"
                                  transform="translate(-168.657 -207.031)"
                                />
                                <path
                                  id="Path_21168"
                                  data-name="Path 21168"
                                  d="M114.661,147.343a2,2,0,0,0-2.8-.1l-6.4,6.1-2.7-2.8a2,2,0,0,0-2.8-.1,2.1,2.1,0,0,0,0,2.9l4.1,4.2a1.8,1.8,0,0,0,1.4.6,2,2,0,0,0,1.4-.6l7.8-7.4a1.9,1.9,0,0,0,.115-2.684C114.739,147.418,114.7,147.38,114.661,147.343Z"
                                  transform="translate(-89.122 -118.639)"
                                />
                                <path
                                  id="Path_21169"
                                  data-name="Path 21169"
                                  d="M223.546,172.442h-23a2,2,0,0,0,0,4h23a2,2,0,1,0,0-4Z"
                                  transform="translate(-168.657 -139.438)"
                                />
                                <path
                                  id="Path_21170"
                                  data-name="Path 21170"
                                  d="M114.661,314.526a2,2,0,0,0-2.8-.1l-6.4,6.1-2.7-2.8a2,2,0,0,0-2.8-.1,2.1,2.1,0,0,0,0,2.9l4.1,4.2a1.8,1.8,0,0,0,1.4.6,2,2,0,0,0,1.4-.6l7.8-7.4a1.9,1.9,0,0,0,.115-2.684C114.739,314.6,114.7,314.563,114.661,314.526Z"
                                  transform="translate(-89.122 -253.824)"
                                />
                                <path
                                  id="Path_21171"
                                  data-name="Path 21171"
                                  d="M223.546,339.626h-23a2,2,0,0,0,0,4h23a2,2,0,1,0,0-4Z"
                                  transform="translate(-168.657 -274.625)"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                        {translation("dashboard_orders")}
                      </h5>
                    </Link>
                  </li>
                  <li
                    className={
                      window.location.pathname === ROUTES.SHEET_INFORMATION
                        ? "active"
                        : ""
                    }
                  >
                    <Link to={ROUTES.SHEET_INFORMATION}>
                      <h5>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 67.196 84"
                        >
                          <g
                            id="clipboard_1_"
                            data-name="clipboard (1)"
                            transform="translate(-47.037)"
                            opacity="1"
                          >
                            <g
                              id="Group_17905"
                              data-name="Group 17905"
                              transform="translate(47.037 0)"
                            >
                              <g
                                id="Group_17904"
                                data-name="Group 17904"
                                transform="translate(0 0)"
                              >
                                <path
                                  id="Path_21165"
                                  data-name="Path 21165"
                                  d="M105.681,11.006H97.943v-3.4c0-1.1-1.222-1.6-2.342-1.6H89.595c-1.425-4-4.989-6-9.061-6a9.265,9.265,0,0,0-9.061,6H65.567c-1.12,0-2.24.5-2.24,1.6v3.4H55.589a8.585,8.585,0,0,0-8.552,8.1V76.4c0,4.4,4.072,7.6,8.552,7.6h50.091c4.48,0,8.552-3.2,8.552-7.6v-57.3A8.585,8.585,0,0,0,105.681,11.006Zm-38.281-1H73a2.223,2.223,0,0,0,1.935-1.8,5.88,5.88,0,0,1,5.6-4.5,5.776,5.776,0,0,1,5.5,4.5,2.226,2.226,0,0,0,2.036,1.8h5.8v8H67.4ZM110.16,76.4c0,2.2-2.24,3.6-4.48,3.6H55.589c-2.24,0-4.48-1.4-4.48-3.6v-57.3a4.548,4.548,0,0,1,4.48-4.1h7.738v5.1a2.126,2.126,0,0,0,2.24,1.9H95.6a2.229,2.229,0,0,0,2.342-1.9v-5.1h7.738a4.549,4.549,0,0,1,4.48,4.1V76.4Z"
                                  transform="translate(-47.037 0)"
                                />
                                <path
                                  id="Path_21166"
                                  data-name="Path 21166"
                                  d="M114.661,230.935a2,2,0,0,0-2.8-.1l-6.4,6.1-2.7-2.8a2,2,0,0,0-2.8-.1,2.1,2.1,0,0,0,0,2.9l4.1,4.2a1.8,1.8,0,0,0,1.4.6,2,2,0,0,0,1.4-.6l7.8-7.4a1.9,1.9,0,0,0,.115-2.684C114.739,231.01,114.7,230.972,114.661,230.935Z"
                                  transform="translate(-89.122 -186.232)"
                                />
                                <path
                                  id="Path_21167"
                                  data-name="Path 21167"
                                  d="M223.546,256.034h-23a2,2,0,1,0,0,4h23a2,2,0,1,0,0-4Z"
                                  transform="translate(-168.657 -207.031)"
                                />
                                <path
                                  id="Path_21168"
                                  data-name="Path 21168"
                                  d="M114.661,147.343a2,2,0,0,0-2.8-.1l-6.4,6.1-2.7-2.8a2,2,0,0,0-2.8-.1,2.1,2.1,0,0,0,0,2.9l4.1,4.2a1.8,1.8,0,0,0,1.4.6,2,2,0,0,0,1.4-.6l7.8-7.4a1.9,1.9,0,0,0,.115-2.684C114.739,147.418,114.7,147.38,114.661,147.343Z"
                                  transform="translate(-89.122 -118.639)"
                                />
                                <path
                                  id="Path_21169"
                                  data-name="Path 21169"
                                  d="M223.546,172.442h-23a2,2,0,0,0,0,4h23a2,2,0,1,0,0-4Z"
                                  transform="translate(-168.657 -139.438)"
                                />
                                <path
                                  id="Path_21170"
                                  data-name="Path 21170"
                                  d="M114.661,314.526a2,2,0,0,0-2.8-.1l-6.4,6.1-2.7-2.8a2,2,0,0,0-2.8-.1,2.1,2.1,0,0,0,0,2.9l4.1,4.2a1.8,1.8,0,0,0,1.4.6,2,2,0,0,0,1.4-.6l7.8-7.4a1.9,1.9,0,0,0,.115-2.684C114.739,314.6,114.7,314.563,114.661,314.526Z"
                                  transform="translate(-89.122 -253.824)"
                                />
                                <path
                                  id="Path_21171"
                                  data-name="Path 21171"
                                  d="M223.546,339.626h-23a2,2,0,0,0,0,4h23a2,2,0,1,0,0-4Z"
                                  transform="translate(-168.657 -274.625)"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                        {translation("sheet_information")}
                      </h5>
                    </Link>
                  </li>
                  <li
                    className={
                      window.location.pathname === ROUTES.SETTINGS
                        ? "active"
                        : ""
                    }
                  >
                    <Link to={ROUTES.SETTINGS}>
                      <h5>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 67.196 84"
                        >
                          <g
                            id="clipboard_1_"
                            data-name="clipboard (1)"
                            transform="translate(-47.037)"
                            opacity="1"
                          >
                            <g
                              id="Group_17905"
                              data-name="Group 17905"
                              transform="translate(47.037 0)"
                            >
                              <g
                                id="Group_17904"
                                data-name="Group 17904"
                                transform="translate(0 0)"
                              >
                                <path
                                  id="Path_21165"
                                  data-name="Path 21165"
                                  d="M105.681,11.006H97.943v-3.4c0-1.1-1.222-1.6-2.342-1.6H89.595c-1.425-4-4.989-6-9.061-6a9.265,9.265,0,0,0-9.061,6H65.567c-1.12,0-2.24.5-2.24,1.6v3.4H55.589a8.585,8.585,0,0,0-8.552,8.1V76.4c0,4.4,4.072,7.6,8.552,7.6h50.091c4.48,0,8.552-3.2,8.552-7.6v-57.3A8.585,8.585,0,0,0,105.681,11.006Zm-38.281-1H73a2.223,2.223,0,0,0,1.935-1.8,5.88,5.88,0,0,1,5.6-4.5,5.776,5.776,0,0,1,5.5,4.5,2.226,2.226,0,0,0,2.036,1.8h5.8v8H67.4ZM110.16,76.4c0,2.2-2.24,3.6-4.48,3.6H55.589c-2.24,0-4.48-1.4-4.48-3.6v-57.3a4.548,4.548,0,0,1,4.48-4.1h7.738v5.1a2.126,2.126,0,0,0,2.24,1.9H95.6a2.229,2.229,0,0,0,2.342-1.9v-5.1h7.738a4.549,4.549,0,0,1,4.48,4.1V76.4Z"
                                  transform="translate(-47.037 0)"
                                />
                                <path
                                  id="Path_21166"
                                  data-name="Path 21166"
                                  d="M114.661,230.935a2,2,0,0,0-2.8-.1l-6.4,6.1-2.7-2.8a2,2,0,0,0-2.8-.1,2.1,2.1,0,0,0,0,2.9l4.1,4.2a1.8,1.8,0,0,0,1.4.6,2,2,0,0,0,1.4-.6l7.8-7.4a1.9,1.9,0,0,0,.115-2.684C114.739,231.01,114.7,230.972,114.661,230.935Z"
                                  transform="translate(-89.122 -186.232)"
                                />
                                <path
                                  id="Path_21167"
                                  data-name="Path 21167"
                                  d="M223.546,256.034h-23a2,2,0,1,0,0,4h23a2,2,0,1,0,0-4Z"
                                  transform="translate(-168.657 -207.031)"
                                />
                                <path
                                  id="Path_21168"
                                  data-name="Path 21168"
                                  d="M114.661,147.343a2,2,0,0,0-2.8-.1l-6.4,6.1-2.7-2.8a2,2,0,0,0-2.8-.1,2.1,2.1,0,0,0,0,2.9l4.1,4.2a1.8,1.8,0,0,0,1.4.6,2,2,0,0,0,1.4-.6l7.8-7.4a1.9,1.9,0,0,0,.115-2.684C114.739,147.418,114.7,147.38,114.661,147.343Z"
                                  transform="translate(-89.122 -118.639)"
                                />
                                <path
                                  id="Path_21169"
                                  data-name="Path 21169"
                                  d="M223.546,172.442h-23a2,2,0,0,0,0,4h23a2,2,0,1,0,0-4Z"
                                  transform="translate(-168.657 -139.438)"
                                />
                                <path
                                  id="Path_21170"
                                  data-name="Path 21170"
                                  d="M114.661,314.526a2,2,0,0,0-2.8-.1l-6.4,6.1-2.7-2.8a2,2,0,0,0-2.8-.1,2.1,2.1,0,0,0,0,2.9l4.1,4.2a1.8,1.8,0,0,0,1.4.6,2,2,0,0,0,1.4-.6l7.8-7.4a1.9,1.9,0,0,0,.115-2.684C114.739,314.6,114.7,314.563,114.661,314.526Z"
                                  transform="translate(-89.122 -253.824)"
                                />
                                <path
                                  id="Path_21171"
                                  data-name="Path 21171"
                                  d="M223.546,339.626h-23a2,2,0,0,0,0,4h23a2,2,0,1,0,0-4Z"
                                  transform="translate(-168.657 -274.625)"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                        {translation("settings")}
                      </h5>
                    </Link>
                  </li>
                  <li
                    className={
                      window.location.pathname === ROUTES.PACKAGE_DETAIL
                        ? "active"
                        : ""
                    }
                  >
                    <Link to={ROUTES.PACKAGE_DETAIL}>
                      <h5>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 67.196 84"
                        >
                          <g
                            id="clipboard_1_"
                            data-name="clipboard (1)"
                            transform="translate(-47.037)"
                            opacity="1"
                          >
                            <g
                              id="Group_17905"
                              data-name="Group 17905"
                              transform="translate(47.037 0)"
                            >
                              <g
                                id="Group_17904"
                                data-name="Group 17904"
                                transform="translate(0 0)"
                              >
                                <path
                                  id="Path_21165"
                                  data-name="Path 21165"
                                  d="M105.681,11.006H97.943v-3.4c0-1.1-1.222-1.6-2.342-1.6H89.595c-1.425-4-4.989-6-9.061-6a9.265,9.265,0,0,0-9.061,6H65.567c-1.12,0-2.24.5-2.24,1.6v3.4H55.589a8.585,8.585,0,0,0-8.552,8.1V76.4c0,4.4,4.072,7.6,8.552,7.6h50.091c4.48,0,8.552-3.2,8.552-7.6v-57.3A8.585,8.585,0,0,0,105.681,11.006Zm-38.281-1H73a2.223,2.223,0,0,0,1.935-1.8,5.88,5.88,0,0,1,5.6-4.5,5.776,5.776,0,0,1,5.5,4.5,2.226,2.226,0,0,0,2.036,1.8h5.8v8H67.4ZM110.16,76.4c0,2.2-2.24,3.6-4.48,3.6H55.589c-2.24,0-4.48-1.4-4.48-3.6v-57.3a4.548,4.548,0,0,1,4.48-4.1h7.738v5.1a2.126,2.126,0,0,0,2.24,1.9H95.6a2.229,2.229,0,0,0,2.342-1.9v-5.1h7.738a4.549,4.549,0,0,1,4.48,4.1V76.4Z"
                                  transform="translate(-47.037 0)"
                                />
                                <path
                                  id="Path_21166"
                                  data-name="Path 21166"
                                  d="M114.661,230.935a2,2,0,0,0-2.8-.1l-6.4,6.1-2.7-2.8a2,2,0,0,0-2.8-.1,2.1,2.1,0,0,0,0,2.9l4.1,4.2a1.8,1.8,0,0,0,1.4.6,2,2,0,0,0,1.4-.6l7.8-7.4a1.9,1.9,0,0,0,.115-2.684C114.739,231.01,114.7,230.972,114.661,230.935Z"
                                  transform="translate(-89.122 -186.232)"
                                />
                                <path
                                  id="Path_21167"
                                  data-name="Path 21167"
                                  d="M223.546,256.034h-23a2,2,0,1,0,0,4h23a2,2,0,1,0,0-4Z"
                                  transform="translate(-168.657 -207.031)"
                                />
                                <path
                                  id="Path_21168"
                                  data-name="Path 21168"
                                  d="M114.661,147.343a2,2,0,0,0-2.8-.1l-6.4,6.1-2.7-2.8a2,2,0,0,0-2.8-.1,2.1,2.1,0,0,0,0,2.9l4.1,4.2a1.8,1.8,0,0,0,1.4.6,2,2,0,0,0,1.4-.6l7.8-7.4a1.9,1.9,0,0,0,.115-2.684C114.739,147.418,114.7,147.38,114.661,147.343Z"
                                  transform="translate(-89.122 -118.639)"
                                />
                                <path
                                  id="Path_21169"
                                  data-name="Path 21169"
                                  d="M223.546,172.442h-23a2,2,0,0,0,0,4h23a2,2,0,1,0,0-4Z"
                                  transform="translate(-168.657 -139.438)"
                                />
                                <path
                                  id="Path_21170"
                                  data-name="Path 21170"
                                  d="M114.661,314.526a2,2,0,0,0-2.8-.1l-6.4,6.1-2.7-2.8a2,2,0,0,0-2.8-.1,2.1,2.1,0,0,0,0,2.9l4.1,4.2a1.8,1.8,0,0,0,1.4.6,2,2,0,0,0,1.4-.6l7.8-7.4a1.9,1.9,0,0,0,.115-2.684C114.739,314.6,114.7,314.563,114.661,314.526Z"
                                  transform="translate(-89.122 -253.824)"
                                />
                                <path
                                  id="Path_21171"
                                  data-name="Path 21171"
                                  d="M223.546,339.626h-23a2,2,0,0,0,0,4h23a2,2,0,1,0,0-4Z"
                                  transform="translate(-168.657 -274.625)"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                        {translation("package_detail")}
                      </h5>
                    </Link>
                  </li>
                  <li
                    className={
                      window.location.pathname === ROUTES.SHEET_WEIGHT
                        ? "active"
                        : ""
                    }
                  >
                    <Link to={ROUTES.SHEET_WEIGHT}>
                      <h5>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 67.196 84"
                        >
                          <g
                            id="clipboard_1_"
                            data-name="clipboard (1)"
                            transform="translate(-47.037)"
                            opacity="1"
                          >
                            <g
                              id="Group_17905"
                              data-name="Group 17905"
                              transform="translate(47.037 0)"
                            >
                              <g
                                id="Group_17904"
                                data-name="Group 17904"
                                transform="translate(0 0)"
                              >
                                <path
                                  id="Path_21165"
                                  data-name="Path 21165"
                                  d="M105.681,11.006H97.943v-3.4c0-1.1-1.222-1.6-2.342-1.6H89.595c-1.425-4-4.989-6-9.061-6a9.265,9.265,0,0,0-9.061,6H65.567c-1.12,0-2.24.5-2.24,1.6v3.4H55.589a8.585,8.585,0,0,0-8.552,8.1V76.4c0,4.4,4.072,7.6,8.552,7.6h50.091c4.48,0,8.552-3.2,8.552-7.6v-57.3A8.585,8.585,0,0,0,105.681,11.006Zm-38.281-1H73a2.223,2.223,0,0,0,1.935-1.8,5.88,5.88,0,0,1,5.6-4.5,5.776,5.776,0,0,1,5.5,4.5,2.226,2.226,0,0,0,2.036,1.8h5.8v8H67.4ZM110.16,76.4c0,2.2-2.24,3.6-4.48,3.6H55.589c-2.24,0-4.48-1.4-4.48-3.6v-57.3a4.548,4.548,0,0,1,4.48-4.1h7.738v5.1a2.126,2.126,0,0,0,2.24,1.9H95.6a2.229,2.229,0,0,0,2.342-1.9v-5.1h7.738a4.549,4.549,0,0,1,4.48,4.1V76.4Z"
                                  transform="translate(-47.037 0)"
                                />
                                <path
                                  id="Path_21166"
                                  data-name="Path 21166"
                                  d="M114.661,230.935a2,2,0,0,0-2.8-.1l-6.4,6.1-2.7-2.8a2,2,0,0,0-2.8-.1,2.1,2.1,0,0,0,0,2.9l4.1,4.2a1.8,1.8,0,0,0,1.4.6,2,2,0,0,0,1.4-.6l7.8-7.4a1.9,1.9,0,0,0,.115-2.684C114.739,231.01,114.7,230.972,114.661,230.935Z"
                                  transform="translate(-89.122 -186.232)"
                                />
                                <path
                                  id="Path_21167"
                                  data-name="Path 21167"
                                  d="M223.546,256.034h-23a2,2,0,1,0,0,4h23a2,2,0,1,0,0-4Z"
                                  transform="translate(-168.657 -207.031)"
                                />
                                <path
                                  id="Path_21168"
                                  data-name="Path 21168"
                                  d="M114.661,147.343a2,2,0,0,0-2.8-.1l-6.4,6.1-2.7-2.8a2,2,0,0,0-2.8-.1,2.1,2.1,0,0,0,0,2.9l4.1,4.2a1.8,1.8,0,0,0,1.4.6,2,2,0,0,0,1.4-.6l7.8-7.4a1.9,1.9,0,0,0,.115-2.684C114.739,147.418,114.7,147.38,114.661,147.343Z"
                                  transform="translate(-89.122 -118.639)"
                                />
                                <path
                                  id="Path_21169"
                                  data-name="Path 21169"
                                  d="M223.546,172.442h-23a2,2,0,0,0,0,4h23a2,2,0,1,0,0-4Z"
                                  transform="translate(-168.657 -139.438)"
                                />
                                <path
                                  id="Path_21170"
                                  data-name="Path 21170"
                                  d="M114.661,314.526a2,2,0,0,0-2.8-.1l-6.4,6.1-2.7-2.8a2,2,0,0,0-2.8-.1,2.1,2.1,0,0,0,0,2.9l4.1,4.2a1.8,1.8,0,0,0,1.4.6,2,2,0,0,0,1.4-.6l7.8-7.4a1.9,1.9,0,0,0,.115-2.684C114.739,314.6,114.7,314.563,114.661,314.526Z"
                                  transform="translate(-89.122 -253.824)"
                                />
                                <path
                                  id="Path_21171"
                                  data-name="Path 21171"
                                  d="M223.546,339.626h-23a2,2,0,0,0,0,4h23a2,2,0,1,0,0-4Z"
                                  transform="translate(-168.657 -274.625)"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                        {translation("sheet_weight")}
                      </h5>
                    </Link>
                  </li>
                  <li
                    onClick={() => setShowPasswordModal(!showPasswordModal)}
                    className={showPasswordModal ? "active" : ""}
                  >
                    <h5 className="unique-link cursorA">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        width="30"
                        height="30"
                        x="0px"
                        y="0px"
                      >
                        <g>
                          <path d="M465.6249,146.9805a11.2469,11.2469,0,1,0-19.9511,10.3886A212.9154,212.9154,0,0,1,407.1425,407.207c-83.3379,83.32-218.9355,83.3379-302.291,0a11.2465,11.2465,0,0,0-19.2041,7.9541v56.9a11.25,11.25,0,0,0,22.5,0V440.3506a236.62,236.62,0,0,0,314.9033-17.2354C496.3515,349.8145,513.4638,238.8438,465.6249,146.9805Z" />
                          <path d="M104.8515,104.916a213.7356,213.7356,0,0,1,302.291,0,11.2465,11.2465,0,0,0,19.2041-7.9541v-56.9a11.25,11.25,0,0,0-22.5,0V71.7549A236.3026,236.3026,0,0,0,46.3779,365.1426,11.2469,11.2469,0,0,0,66.329,354.7539,212.9,212.9,0,0,1,104.8515,104.916Z" />
                          <path d="M255.997,82.8115A89.1082,89.1082,0,0,0,166.99,171.8184V216.37H153.4023a38.29,38.29,0,0,0-38.25,38.25V364.0615a38.29,38.29,0,0,0,38.25,38.25H358.6005a38.29,38.29,0,0,0,38.25-38.25V254.62a38.29,38.29,0,0,0-38.25-38.25H345.0126V171.8184A89.11,89.11,0,0,0,255.997,82.8115ZM189.49,171.8184a66.5112,66.5112,0,0,1,133.0224,0V216.37H189.49Zm184.86,82.8017V364.0615a15.7691,15.7691,0,0,1-15.75,15.75H153.4023a15.7691,15.7691,0,0,1-15.75-15.75V254.62a15.7691,15.7691,0,0,1,15.75-15.75H358.6005A15.7691,15.7691,0,0,1,374.3505,254.62Z" />
                          <circle cx="327.997" cy="309.3408" r="22.5" />
                          <circle cx="255.997" cy="309.3408" r="22.5" />
                          <circle cx="183.997" cy="309.3408" r="22.5" />
                        </g>
                      </svg>
                      {translation("dashboard_password")}
                    </h5>
                  </li>
                </>
              )}

              {authReducer.authData.account_type === ROLES.ARTIST && (
                <>
                  <li
                  className={
                    window.location.pathname === ROUTES.JOB_REQUEST
                      ? "active"
                      : ""
                  }
                >
                  <Link to={ROUTES.JOB_REQUEST}>
                    <div className="d-flex justify-content-between">
                      {" "}
                      <h5>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 84 84"
                        >
                          <g
                            id="printer_1_"
                            data-name="printer (1)"
                            opacity="1"
                          >
                            <path
                              id="Path_3744"
                              data-name="Path 3744"
                              d="M71.7,21.164H69.4V12.3A12.319,12.319,0,0,0,57.094,0H26.906A12.319,12.319,0,0,0,14.6,12.3v8.859H12.3A12.319,12.319,0,0,0,0,33.469V53.156a12.319,12.319,0,0,0,12.3,12.3h2.3V76.617A7.391,7.391,0,0,0,21.984,84H62.016A7.391,7.391,0,0,0,69.4,76.617V65.461h2.3A12.319,12.319,0,0,0,84,53.156V33.469A12.319,12.319,0,0,0,71.7,21.164ZM19.523,12.3a7.391,7.391,0,0,1,7.383-7.383H57.094A7.391,7.391,0,0,1,64.477,12.3v8.859H19.523ZM64.477,76.617a2.464,2.464,0,0,1-2.461,2.461H21.984a2.464,2.464,0,0,1-2.461-2.461V52.336H64.477Zm14.6-23.461A7.391,7.391,0,0,1,71.7,60.539H69.4v-8.2h1.477a2.461,2.461,0,0,0,0-4.922H13.125a2.461,2.461,0,0,0,0,4.922H14.6v8.2H12.3a7.391,7.391,0,0,1-7.383-7.383V33.469A7.391,7.391,0,0,1,12.3,26.086H71.7a7.391,7.391,0,0,1,7.383,7.383Z"
                            />
                            <path
                              id="Path_3745"
                              data-name="Path 3745"
                              d="M216.624,353H203.467a2.467,2.467,0,1,0,0,4.934h13.157a2.467,2.467,0,0,0,0-4.934Z"
                              transform="translate(-168.046 -295.095)"
                            />
                            <path
                              id="Path_3746"
                              data-name="Path 3746"
                              d="M216.624,417H203.467a2.467,2.467,0,1,0,0,4.934h13.157a2.467,2.467,0,0,0,0-4.934Z"
                              transform="translate(-168.046 -348.624)"
                            />
                            <path
                              id="Path_3747"
                              data-name="Path 3747"
                              d="M75.361,193H67.467a2.467,2.467,0,0,0,0,4.934h7.894a2.467,2.467,0,0,0,0-4.934Z"
                              transform="translate(-54.31 -161.341)"
                            />
                          </g>
                        </svg>
                        {translation("dashboard_jobs")}
                      </h5>
                    </div>
                  </Link>
                </li>
                <li
                    className={
                      window.location.pathname === ROUTES.USER_LIST
                        ? "active"
                        : ""
                    }
                  >
                    <Link to={ROUTES.USER_LIST}>
                      <h5>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 84 84"
                        >
                          <g id="user" opacity="1">
                            <g
                              id="Group_17903"
                              data-name="Group 17903"
                              transform="translate(0)"
                            >
                              <path
                                id="Path_21160"
                                data-name="Path 21160"
                                d="M71.7,54.3a41.839,41.839,0,0,0-15.96-10.011,24.281,24.281,0,1,0-27.477,0A42.066,42.066,0,0,0,0,84H6.562a35.438,35.438,0,0,1,70.875,0H84A41.726,41.726,0,0,0,71.7,54.3ZM42,42A17.719,17.719,0,1,1,59.719,24.281,17.739,17.739,0,0,1,42,42Z"
                                transform="translate(0)"
                              />
                            </g>
                          </g>
                        </svg>
                        {translation("users_dashboard")}
                      </h5>
                    </Link>
                  </li>
                </>
              )}
              <div className="p-4">
                <CommonButton
                  className="theme-button primary-btn"
                  type="button"
                  onClick={() => setShowConfirmModal(!showConfirmModal)}
                >
                  <svg
                    className="me-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 84 81.802"
                  >
                    <path
                      id="Logout"
                      d="M40.1,2.021A18.141,18.141,0,0,1,58.216,19.307l.02.854v3.816a3.068,3.068,0,0,1-6.107.416l-.028-.416V20.161A12.006,12.006,0,0,0,40.8,8.177l-.705-.02H20.157a12,12,0,0,0-11.98,11.3l-.02.705V65.684a12.005,12.005,0,0,0,11.3,11.984l.7.02h19.98A11.964,11.964,0,0,0,52.081,66.431l.02-.7V61.872a3.068,3.068,0,0,1,6.107-.416l.028.416v3.857A18.1,18.1,0,0,1,41.014,83.8l-.877.021H20.157A18.14,18.14,0,0,1,2.042,66.537l-.02-.854V20.161A18.14,18.14,0,0,1,19.3,2.041l.854-.02ZM85.076,40.706a3.068,3.068,0,0,1-.018,4.448L73.142,57.022a3.068,3.068,0,0,1-4.627-4l.3-.343,6.708-6.685H33.706a3.068,3.068,0,0,1-.416-6.107l.416-.028H75.528l-6.715-6.682a3.069,3.069,0,0,1-.306-3.993l.3-.345a3.068,3.068,0,0,1,3.993-.306l.345.3Z"
                      transform="translate(-2.022 -2.021)"
                      fillRule="evenodd"
                      opacity="1"
                      fill="#fff"
                    />
                  </svg>
                  {translation("dashboard_logout")}
                </CommonButton>
              </div>
            </ul>
          </div>
          <p className="p-4 op-6">{translation("made_by_biz4group")}</p>
        </div>
      </div>

      {showConfirmModal && (
        <ConfirmationModal
          heading="Logout"
          description="logout_modal_description"
          onClickOkay={() => handleLogOut()}
          onClickCancel={() => setShowConfirmModal(false)}
        />
      )}
      {showPasswordModal && (
        <PasswordModal
          closeModal={() => setShowPasswordModal(false)}
          getdata={changePasswordInfo}
          loading={loading}
        />
      )}
    </>
  );
};
export default Sidebar;
