import * as http from "../utils/http";
import endpoint from "../constant/endPoint";
import ApiResponse from "../resources/entity/IApiResponse";
import { SheetData, SheetWeightData } from "../interfaces/commonInterface";

/**
 * Delete sheet
 * @param data Sheet id
 * @returns
 */
const deleteSheet = (data: { id: number }): Promise<ApiResponse> =>
  http.remove(endpoint.sheet.DELETE_SHEET, data);

/**
 * Update sheet
 * @param data Sheet list
 * @returns
 */
const updateSheet = (data: SheetData[]): Promise<ApiResponse> =>
  http.post(endpoint.sheet.UPDATE_SHEET, data);

/**
 * Getsheet info
 * @returns
 */
const getSheetInfo = (): Promise<ApiResponse> =>
  http.get(endpoint.sheet.GET_SHEET_INFO);

/**
 * Delete sheet
 * @param data Sheet id
 * @returns
 */
const deleteSheetWeight = (data: { id: number }): Promise<ApiResponse> =>
  http.remove(endpoint.sheetWeight.UPDATE_SHEETWEIGHT, data);

/**
 * Update sheet
 * @param data Sheet list
 * @returns
 */
const updateSheetWeight = (data: SheetWeightData[]): Promise<ApiResponse> =>
  http.post(endpoint.sheetWeight.UPDATE_SHEETWEIGHT, data);

/**
 * Getsheet info
 * @returns
 */
const getSheetWeightInfo = (): Promise<ApiResponse> =>
  http.get(endpoint.sheetWeight.GET_SHEETWEIGHT_INFO);

/**
 * Getsheet info
 * @returns
 */
const getSheetPrices = (): Promise<ApiResponse> =>
  http.get(endpoint.sheet.GET_SHEET_PRICES);

export {
  deleteSheet,
  updateSheet,
  getSheetInfo,
  deleteSheetWeight,
  updateSheetWeight,
  getSheetWeightInfo,
  getSheetPrices,
};
