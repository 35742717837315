import { http } from "../utils/http";
import endpoint from "../constant/endPoint";
import ApiResponse from "../resources/entity/IApiResponse";

// get count on Dashboard

const dashboardCount = async (): Promise<ApiResponse> => {
  return http.get(endpoint.admin.DASHBOARD_COUNT);
};
const dashboardInfo = async (): Promise<ApiResponse> => {
  return http.get(endpoint.admin.DASHBOARD_INFO);
};

const getAdminSeetings = async (title:string): Promise<ApiResponse> => {
  return http.get(endpoint.setting.GET_ADMIN_SEETINGS.concat(`?title=${title}`));
};
export { dashboardCount, dashboardInfo ,getAdminSeetings };
