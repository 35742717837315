import { LIST_RECORDS_LIMIT } from "../../constant/commonConstants";

export interface INotification {
  id: number;
  type: string;
  message: string;
  status:"read"|"unread"
  created_ts: Date;
}


interface IList<T> {
  list: T[];
  offset: number;
  prevOffset: number;
  hasMore: boolean;
}


interface IAction<T> {
  payload: IList<T>;
  type: "LIST";
  firstLoad: boolean;
}

export const initialListState: IList<any> = {
  list: [],
  offset: 0,
  prevOffset: 0,
  hasMore: false,
};

const listReducer = <T>(
  state = initialListState,
  { payload, type, firstLoad }: IAction<T>
) => {
  if (type === "LIST") {
    if (payload?.list?.length <= LIST_RECORDS_LIMIT) {
      return {
        ...state,
        prevOffset: firstLoad ? 0 : state.offset,
        offset: firstLoad ? 1 : state.offset + 1,
        list: firstLoad
          ? payload.list
          : state.list.concat(payload?.list),
        hasMore:payload?.list?.length >= LIST_RECORDS_LIMIT
      };
    }

    return {
      ...state,
      list: firstLoad
        ? payload.list
        : state.list.concat(payload?.list),
      hasMore: false,
    };
  }
  return initialListState;
};
export default listReducer;
