import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

import CommonTextBox from "../../components/commonFields/commonTextBox";
import CommonButton from "../../components/commonFields/CommonButton";
import ConfirmationModal from "../../components/commonModals/ConfirmationModal";
import CommonSelect from "../../components/commonFields/CommonSelect";
import sheetValidationSchema from "../../validationSchemas/sheetInformationValidations";
import toast from "../../utils/toastsMessage";

import {
  deleteSheet,
  getSheetInfo,
  getSheetWeightInfo,
  updateSheet,
} from "../../services/sheet";
import {
  ISheetWeightDataRes,
  SheetData,
  SheetInfoRes,
} from "../../interfaces/commonInterface";
import { MEMBERSHIP_PLANS_TYPE } from "../../constant/commonConstants";
import warning from "../../assets/images/warning.svg";

const SheetInformation: React.FC = () => {
  const { t: translation } = useTranslation();

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(sheetValidationSchema(translation)),
  });

  const { fields, remove, append } = useFieldArray({
    name: "sheetInformation",
    control,
  });
  const [showConfirmationModal, setShowConfirmationModal] =useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [sheetId, setSheetId] = useState<number>();
  const [sheetIndex, setSheetIndex] = useState(0);

  const handleSheetInformation = async () => {
    if (sheetId) {
      setLoading(true);

      const deleteRes = await deleteSheet({ id: sheetId });
      if (deleteRes?.data?.success) {
        toast.success(translation(deleteRes?.data?.message));
        setShowConfirmationModal(false);
        remove(sheetIndex);
      } else toast.error(translation(deleteRes?.data?.message));
    } else {
      setShowConfirmationModal(false);
      remove(sheetIndex);
    }

    setLoading(false);
  };

  const onSubmit = async (data: SheetData[]) => {
    setSubmitLoading(true);
    const res = await updateSheet(data);
    if (res?.data?.success) toast.success(translation(res?.data?.message));
    else toast.error(translation(res?.data?.message));
    setSubmitLoading(false);
  };

  function checkWeight(info: ISheetWeightDataRes[], sheet: SheetInfoRes) {
    let weight = 0;
    info.forEach((item: ISheetWeightDataRes) => {
      if (
        +item.size.split(" ")[0] === sheet.length &&
        +item.size.split(" ")[3] === sheet.width
      )
        weight = item.sheet_weight;
    });
    return weight;
  }

  const getSheetList = async () => {
    setLoading(true);
    const sheetres = await getSheetWeightInfo();

    const res = await getSheetInfo();
    if (res?.data?.data?.length > 0) {
      res?.data?.data?.forEach((sheet: SheetInfoRes) => {
        let sheetWeight = 0;
        if (sheetres?.data?.success) {
          sheetWeight = checkWeight(sheetres?.data?.data, sheet);
        }

        append({
          sheetLength: sheet.length,
          sheetWidth: sheet.width,
          minQuantity: sheet.min_quantity,
          maxQuantity: sheet.max_quantity,
          sheetPrice: sheet.price_per_sheet,
          sheetId: sheet.id,
          membershipType: sheet.membership_type,
          discount: sheet.discount,
          weight: sheetWeight > 0 ? sheetWeight : undefined,
        });
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getSheetList();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="p-4">
          <div className="white-card mb-4">
            <div className="d-flex align-items-center justify-content-between">
              <h1>{translation("sheet_list")}</h1>
              <CommonButton
                className="theme-button primary-btn me-0 ms-0"
                type="button"
                onClick={() =>
                  append({
                    sheetLength: undefined,
                    sheetWidth: undefined,
                    minQuantity: undefined,
                    maxQuantity: undefined,
                    sheetPrice: undefined,
                    sheetId: undefined,
                    membershipType: undefined,
                    discount: undefined,
                  })
                }
              >
                {translation("add_new")}
              </CommonButton>
            </div>
          </div>

          <div className="white-card">
            <form
              onSubmit={handleSubmit((data) =>
                onSubmit(data.sheetInformation as SheetData[])
              )}
              noValidate
            >
              <div>
                {fields.length > 0 ? (
                  (fields as SheetData[]).map((item, index: number) => {
                    return (
                      <div className="row mb-2">
                        <div className="col-lg-6">
                          {" "}
                          <input
                            type="hidden"
                            {...register(`sheetInformation[${index}].sheetId`)}
                            value={`fields[${index}].sheetId`}
                          />
                          <div className="form-group w-100">
                            <CommonTextBox
                              control={control}
                              label={translation("sheet_length_dim")}
                              name={`sheetInformation[${index}].sheetLength`}
                              type="number"
                              className="form-control"
                              error={
                                errors?.sheetInformation &&
                                errors?.sheetInformation[index] &&
                                errors?.sheetInformation[index].sheetLength
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          <div className="form-group w-100">
                            <CommonTextBox
                              control={control}
                              label={translation("sheet_width_dim")}
                              name={`sheetInformation[${index}].sheetWidth`}
                              type="number"
                              className="form-control"
                              error={
                                errors?.sheetInformation &&
                                errors?.sheetInformation[index] &&
                                errors?.sheetInformation[index].sheetWidth
                              }
                            />
                          </div>
                        </div>

                        <div className="col-lg-6">
                          {" "}
                          <div className="form-group w-100">
                            <CommonTextBox
                              control={control}
                              label={translation("min_quantity")}
                              name={`sheetInformation[${index}].minQuantity`}
                              type="number"
                              className="form-control"
                              error={
                                errors?.sheetInformation &&
                                errors?.sheetInformation[index] &&
                                errors?.sheetInformation[index].minQuantity
                              }
                            />
                          </div>
                        </div>

                        <div className="col-lg-6">
                          {" "}
                          <div className="form-group w-100">
                            <CommonTextBox
                              control={control}
                              label={translation("max_quantity")}
                              name={`sheetInformation[${index}].maxQuantity`}
                              type="number"
                              className="form-control"
                              error={
                                errors?.sheetInformation &&
                                errors?.sheetInformation[index] &&
                                errors?.sheetInformation[index].maxQuantity
                              }
                            />
                          </div>
                        </div>

                        <div className="col-lg-6">
                          {" "}
                          <div className="form-group w-100">
                            <CommonTextBox
                              control={control}
                              label={translation("sheet_price")}
                              name={`sheetInformation[${index}].sheetPrice`}
                              type="number"
                              className="form-control"
                              error={
                                errors?.sheetInformation &&
                                errors?.sheetInformation[index] &&
                                errors?.sheetInformation[index].sheetPrice
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          <div className="form-group w-100">
                            <div className="form-group">
                              <CommonSelect
                                className="form-control"
                                label={translation("membership_ship")}
                                option={MEMBERSHIP_PLANS_TYPE}
                                name={`sheetInformation[${index}].membershipType`}
                                errors={
                                  errors?.sheetInformation &&
                                  errors?.sheetInformation[index] &&
                                  errors?.sheetInformation[index].membershipType
                                }
                                control={control}
                                firstOption={translation(
                                  "select_memebership_type"
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          <div className="form-group w-100">
                            <CommonTextBox
                              control={control}
                              label={translation("sheet_discount")}
                              name={`sheetInformation[${index}].discount`}
                              type="number"
                              className="form-control"
                              error={
                                errors?.sheetInformation &&
                                errors?.sheetInformation[index] &&
                                errors?.sheetInformation[index].discount
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group w-100">
                            <div className="double-icon-input">
                              <CommonTextBox
                                control={control}
                                disabled
                                label={translation("sheet_weight_lb")}
                                name={`sheetInformation[${index}].weight`}
                                type="text"
                                className="form-control"
                              />
                              {!item.weight ? (
                                <span className="right-icon right-icon-custom custom-tooltip">
                                  <img src={warning} alt="" />
                                  <p className="custom-tooltip-content custom-tooltip-content-custom">
                                    {translation("sheet_weight_error")}
                                  </p>
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 text-center mb-4">
                          {fields.length > 0 && (
                            <button
                              type="button"
                              className="icon-btn primary-btn sheet-list-icon"
                              onClick={() => {
                                setSheetId(item.sheetId);
                                setSheetIndex(index);
                                setShowConfirmationModal(true);
                              }}
                              disabled={submitLoading}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                version="1.1"
                                width="512"
                                height="512"
                                x="0"
                                y="0"
                                viewBox="0 0 427 427.00131"
                                xmlSpace="preserve"
                              >
                                <g>
                                  <path
                                    d="m232.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"
                                    fill="#ffffff"
                                    data-original="#000000"
                                  />
                                  <path
                                    d="m114.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"
                                    fill="#ffffff"
                                    data-original="#000000"
                                  />
                                  <path
                                    d="m28.398438 127.121094v246.378906c0 14.5625 5.339843 28.238281 14.667968 38.050781 9.285156 9.839844 22.207032 15.425781 35.730469 15.449219h189.203125c13.527344-.023438 26.449219-5.609375 35.730469-15.449219 9.328125-9.8125 14.667969-23.488281 14.667969-38.050781v-246.378906c18.542968-4.921875 30.558593-22.835938 28.078124-41.863282-2.484374-19.023437-18.691406-33.253906-37.878906-33.257812h-51.199218v-12.5c.058593-10.511719-4.097657-20.605469-11.539063-28.03125-7.441406-7.421875-17.550781-11.5546875-28.0625-11.46875h-88.796875c-10.511719-.0859375-20.621094 4.046875-28.0625 11.46875-7.441406 7.425781-11.597656 17.519531-11.539062 28.03125v12.5h-51.199219c-19.1875.003906-35.394531 14.234375-37.878907 33.257812-2.480468 19.027344 9.535157 36.941407 28.078126 41.863282zm239.601562 279.878906h-189.203125c-17.097656 0-30.398437-14.6875-30.398437-33.5v-245.5h250v245.5c0 18.8125-13.300782 33.5-30.398438 33.5zm-158.601562-367.5c-.066407-5.207031 1.980468-10.21875 5.675781-13.894531 3.691406-3.675781 8.714843-5.695313 13.925781-5.605469h88.796875c5.210937-.089844 10.234375 1.929688 13.925781 5.605469 3.695313 3.671875 5.742188 8.6875 5.675782 13.894531v12.5h-128zm-71.199219 32.5h270.398437c9.941406 0 18 8.058594 18 18s-8.058594 18-18 18h-270.398437c-9.941407 0-18-8.058594-18-18s8.058593-18 18-18zm0 0"
                                    fill="#ffffff"
                                    data-original="#000000"
                                  />
                                  <path
                                    d="m173.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"
                                    fill="#ffffff"
                                    data-original="#000000"
                                  />
                                </g>
                              </svg>
                            </button>
                          )}
                        </div>
                        <hr />
                      </div>
                    );
                  })
                ) : loading ? (
                  <h3 className="text-center">{translation("loading")}</h3>
                ) : (
                  !loading && <p>{translation("no_data")}</p>
                )}
              </div>
              <div className="text-center pb-4">
                <CommonButton
                  className="theme-button primary-btn me-0 ms-0"
                  type="submit"
                  disabled={!fields.length || submitLoading}
                >
                  {translation("submit_button")}
                  {submitLoading && (
                    <div className="spinner-border text-light" role="status" />
                  )}
                </CommonButton>
              </div>
            </form>
          </div>
        </div>
      </div>
      {showConfirmationModal && (
        <ConfirmationModal
          heading="delete_sheet"
          description="active_deactive"
          onClickOkay={() => handleSheetInformation()}
          onClickCancel={() => setShowConfirmationModal(false)}
          disabled={loading}
        />
      )}
    </>
  );
};
export default SheetInformation;
