import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

import CommonButton from "../../components/commonFields/CommonButton";
import CommonTextBox from "../../components/commonFields/commonTextBox";
import settingValidationSchema from "../../validationSchemas/settingValidation";
import toast from "../../utils/toastsMessage";

import { getSetttings, updateSettings } from "../../services/setting";
import { IsettingData, IsettingList } from "../../interfaces/settingInterface";

const Settigs: React.FC = () => {
  const { t: translation } = useTranslation();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(settingValidationSchema(translation)),
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: IsettingData) => {
    setLoading(true);
    const res = await updateSettings(data);
    if (res?.data?.success) toast.success(translation(res?.data?.message));
    else toast.error(translation(res?.data?.message));
    setLoading(false);
  };

  const getSettingData = async () => {
    const res = await getSetttings();
    if (res?.data?.success) {
      res?.data?.data.forEach((item: IsettingList) => {
        setValue(item.label, item.value);
      });
    }
  };

  useEffect(() => {
    getSettingData();
  }, []);

  return (
    <div className="p-4">
      <div className="white-card">
        <h3 className="mb-3 space">
          <b>
            <span className="color-theme">
              {translation("settings").toUpperCase()}
            </span>
          </b>
        </h3>
        <div>
          <h4 className="mb-3 space color-theme bb1 pb-2">
            {translation("shipping")}
          </h4>
          <form
            onSubmit={handleSubmit((data) => onSubmit(data as IsettingData))}
            noValidate
          >
            <div className="row">
              <div className="col-md-6">
                <div className="form-group w-100">
                  <CommonTextBox
                    control={control}
                    label={translation("consumablecost")}
                    name="consumableCost"
                    type="number"
                    className="form-control"
                    error={errors.consumableCost}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group w-100">
                  <CommonTextBox
                    control={control}
                    label={translation("markup")}
                    name="markup"
                    type="number"
                    className="form-control"
                    error={errors.markup}
                  />
                </div>
              </div>
            </div>
            <div className="text-center mt-2">
              <CommonButton
                className="theme-button primary-btn me-0 ms-0"
                type="submit"
                disabled={loading}
              >
                {translation("submit_button")}
                {loading && (
                  <div className="spinner-border text-light" role="status" />
                )}
              </CommonButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Settigs;
