import { AnyAction } from "redux";
import {
  ISheetWeightDataRes,
  SheetInfoRes,
} from "../../interfaces/commonInterface";
import { IUserInterface } from "../../interfaces/userInterface";
import ActionType from "../../resources/enums/index";
import Storage from "../../utils/storage";

export interface IAuthReducerState {
  isLoggedIn: boolean;
  authData: IUserInterface;
  sheetInfoData: SheetInfoRes[];
  sheetWeightData: ISheetWeightDataRes[];
}
const authData = Storage.get("authData")
  ? JSON.parse(Storage.get("authData") as string)
  : {};
const initialState: IAuthReducerState = {
  isLoggedIn: false,
  authData,
  sheetInfoData: [],
  sheetWeightData: [],
};

const AuthReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionType.LOGIN: {
      return {
        ...state,
        isLoggedIn: true,
        authData: action?.payload,
      };
    }

    case ActionType.LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        authData: {},
      };
    case ActionType.EDIT_PROFILE:
      return {
        ...state,
        authData: { ...state.authData, ...action.payload },
      };
    case ActionType.UPDATE_AUTHORIZR_PROFILE_ID:
      return {
        ...state,
        authData: {
          ...state.authData,
          authorize_customer_id: action.payload.authorizeId,
        },
      };
    case ActionType.UPDATE_STRIPE_PROFILE_ID:
      return {
        ...state,
        authData: {
          ...state.authData,
          stripe_customer_id: action.payload.stripeId,
        },
      };
    case ActionType.EDIT_IMAGE:
      return {
        ...state,
        authData: { ...state.authData, ...action.payload },
      };
    case ActionType.USER_ACCOUNT_TYPES:
      return {
        ...state,
        authData: action.payload.data,
      };
    case ActionType.UPDATE_AUTH:
      return {
        authData: { ...state.authData, ...action.payload.data },
      };
    case ActionType.CHANGE_PASSWORD:
      return {
        ...state,
        isUpdated: action.payload.data.isUpdated,
      };
    case ActionType.CLEAR_AUTH:
      return {
        ...state,
        isRegistered: "",
        isLoggedIn: "",
        authData: {},
        isUpdated: "",
        sheetInfoData: [],
        sheetWeightData: [],
      };
    case ActionType.PENDING:
      return {
        ...state,
        isUpdated: false,
      };
    case ActionType.COMPLETED:
      return {
        ...state,
        isUpdated: true,
      };
    case ActionType.SHEET_INFO:
      return {
        ...state,
        sheetInfoData: action.payload,
      };
    case ActionType.SHEET_WEIGHT:
      return {
        ...state,
        sheetWeightData: action.payload,
      };
    case ActionType.UPDATTE_USER_MEMBERSHIP_PLAN:
      return {
        ...state,
        authData: {
          ...state.authData,
          membership_plan: action?.payload?.membership,
        },
      };
    default:
      return state;
  }
};

export default AuthReducer;
