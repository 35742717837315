import { IsettingList } from "../../interfaces/settingInterface";
import ActionType from "../../resources/enums/index";

export interface IInfoState {
  sheetSetting: IsettingList[];
}

const initialState: IInfoState = {
  sheetSetting: [],
};

interface ShippingSetting {
  type: ActionType.SHIPPING_SETTINGS;
  payload: { sheetSetting: IsettingList[] };
}

interface EmptySetting {
  type: ActionType.EMPTY_SHIPPING_SETTINGS;
}

export type Action = ShippingSetting | EmptySetting;

const SettingReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.SHIPPING_SETTINGS: {
      return {
        ...state,
        sheetSetting: action?.payload.sheetSetting,
      };
    }

    case ActionType.EMPTY_SHIPPING_SETTINGS: {
      return initialState;
    }

    default:
      return state;
  }
};

export default SettingReducer;
