import * as yup from "yup";
import { TFunction } from "react-i18next";
import { array, object } from "yup";
import { SheetData } from "../interfaces/commonInterface";

const sheetValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    sheetInformation: array().of(
      object().shape({
        sheetLength: yup
          .number()
          .required(translation("sheet_length_req"))
          .min(
            1,
            translation("length_not_less_than", {
              limit: 1,
            })
          )
          .max(
            99.99,
            translation("length_not_more_than", {
              limit: 99.99,
            })
          )
          .typeError(translation("sheet_length_req")),
        sheetWidth: yup
          .number()
          .required(translation("sheet_width_req"))
          .min(
            1,
            translation("width_not_less_than", {
              limit: 1,
            })
          )
          .max(
            99.99,
            translation("width_not_more_than", {
              limit: 99.99,
            })
          )
          .typeError(translation("sheet_width_req")),
        minQuantity: yup
          .number()
          .required(translation("min_qty_req"))
          .min(
            1,
            translation("min_qty_not_less_than", {
              limit: 1,
            })
          )
          .max(
            999,
            translation("min_qty_not_more_than", {
              limit: 999,
            })
          )
          .typeError(translation("min_qty_req"))
          .test(
            "isValid",
            translation("invalid min quantity"),
            (value, schema: yup.TestContext["schema"]) => {
              let isValid = true;

              const data = schema;
              const index = parseInt(data.path.split("[")[1].split("]")[0], 10);

              const formData = schema.from[1].value.sheetInformation;
              const indexData = schema.from[0].value;

              function sameData(info: SheetData, i: number) {
                if (
                  info?.sheetLength === parseInt(indexData?.sheetLength, 10) &&
                  info?.sheetWidth === parseInt(indexData?.sheetWidth, 10) &&
                  info.membershipType === indexData.membershipType &&
                  index > i
                )
                  return info;
              }

              if (index > 0) {
                const filterData = formData.filter(sameData);

                if (filterData?.length) {
                  for (let i = 0; i < index; i += 1) {
                    if (
                      parseInt(indexData?.minQuantity, 10) <=
                      parseInt(filterData[i]?.maxQuantity, 10)
                    ) {
                      isValid = false;
                      break;
                    }
                  }
                }
              }

              return isValid;
            }
          ),
        maxQuantity: yup
          .number()
          .required(translation("max_qty_req"))
          .min(
            1,
            translation("max_qty_not_less_than", {
              limit: 1,
            })
          )
          .max(
            999,
            translation("max_qty_not_more_than", {
              limit: 999,
            })
          )
          .moreThan(yup.ref("minQuantity"), translation("invalid_max_quantity"))
          .typeError(translation("max_qty_req")),
        sheetPrice: yup
          .number()
          .required(translation("price_req"))
          .min(
            0,
            translation("price_not_less_than", {
              limit: 1,
            })
          )
          .max(
            999,
            translation("price_not_more_than", {
              limit: 999,
            })
          )
          .typeError(translation("price_req")),
        discount: yup
          .number()
          .required(translation("discount_req"))
          .min(
            0,
            translation("discount_not_less_than", {
              limit: 0,
            })
          )
          .max(
            99,
            translation("discount_not_more_than", {
              limit: 99,
            })
          )
          .typeError(translation("discount_req")),
        membershipType: yup
          .string()
          .required(translation("membershiptype_req")),
      })
    ),
  });

export default sheetValidationSchema;
