import { FC, ReactNode } from "react";

type Props = {
  type?: "submit" | "reset" | "button";
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  title?: string;
  id?: string;
  children?: ReactNode;
};

const CommonButton: FC<Props> = ({
  children,
  type = "button",
  className,
  disabled,
  onClick,
  title,
  id,
}) => {
  return (
    <button
      className={`${className} ${disabled ? "disabled-btn" : ""}`}
      type={type === "button" ? "button" : "submit"}
      disabled={disabled}
      onClick={onClick}
      title={title}
      id={id}
    >
      {children}
    </button>
  );
};

export default CommonButton;
