import * as yup from "yup";
import { TFunction } from "react-i18next";

// Validation for Settings Page fields
const settingValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    consumableCost: yup
      .number()
      .required(translation("consumablecost_requied"))
      .min(
        0,
        translation("min_qty_not_less_than", {
          limit: 0,
        })
      )
      .typeError(translation("consumablecost_requied")),
    markup: yup
      .number()
      .required(translation("markup_requied"))
      .min(
        1,
        translation("min_qty_not_less_than", {
          limit: 1,
        })
      )
      .typeError(translation("markup_requied")),
  });

export default settingValidationSchema;
