import {
  IUserShippingInfo,
  UserOderList,
} from "../../interfaces/orderInterface";
import ActionType from "../../resources/enums/index";

export interface IInfoState {
  userOrderInfo: IUserShippingInfo;
  OrderDetailInfo: UserOderList[];
}

const initialState: IInfoState = {
  userOrderInfo: {} as IUserShippingInfo,
  OrderDetailInfo: [] as UserOderList[],
};

interface UserOrderInfo {
  type: ActionType.USER_ORDER_INFO;
  payload: IUserShippingInfo;
}

interface EmptyOrderInfo {
  type: ActionType.ORDER_EMPTY;
}

interface OrderDetailInfo {
  type: ActionType.ORDER_DETAILS_INFO;
  payload: UserOderList[];
}
interface UpdateOrderDetailInfo {
  type: ActionType.UPDATTE_ORDER_INFO;
  payload: {
    subtotal: number;
    dis: number;
  };
}

export type Action =
  | UserOrderInfo
  | OrderDetailInfo
  | EmptyOrderInfo
  | UpdateOrderDetailInfo;

const OrderReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.USER_ORDER_INFO: {
      return {
        ...state,
        userOrderInfo: action?.payload,
      };
    }

    case ActionType.ORDER_DETAILS_INFO: {
      return {
        ...state,
        OrderDetailInfo: action?.payload,
      };
    }
    case ActionType.ORDER_EMPTY: {
      return initialState;
    }
    case ActionType.UPDATTE_ORDER_INFO: {
      return {
        ...state,
        userOrderInfo: {
          ...state.userOrderInfo,
          subTotal: action?.payload?.subtotal,
          memberShipPlan: action?.payload?.dis,
        },
      };
    }
    default:
      return state;
  }
};

export default OrderReducer;
